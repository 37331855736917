@import "colors";
@include media-breakpoint-down(lg){

}
@include media-breakpoint-down(md){
   // bizden haberler
   .bizden-haberler{
    .lists{
        .fotograf{
            img{
                min-width: 100%;
            }
        }
    }
}
}
@include media-breakpoint-down(sm){
    .header-blok-2{
        .row > *{
            height: initial;

            .logo{
                width:100%;
            }

            &.logo-blok{
                padding:1rem 1rem ;
            }
        }
    }
    nav.ana-menu{
        //background-color: $brand-gray-light2;
        border-bottom:none;
        height:initial;
    }

    .sepet-ikonu{
        img{
            filter:brightness(100) grayscale(0);
        }
    }

    .anasayfa-bagis-bolumu{
        .swiper-button{
            &.swiper-button-next,
            &.swiper-button-prev{
                width: 40px;
                margin-bottom:0;
            }

            &.swiper-button-next{
                margin-right:0;
            }
            &.swiper-button-prev{
                margin-left:0;
            }
        }
    }

    #mobileMenu{
        ul{
            li{
                
                &.active,
                &:hover{
                    a{
                        background-color: $brand-blue-dark;
                    }
                }
                span,
                a{
                    color:white;
                    padding:0.7em 1em;
                    display: block;
                }
                ul{
                    display:none;
                    padding:10px;
                    padding-left:0.5em;
                    background-color:$brand-blue-dark;
                    li{
                        a{
                            &:before{
                                content:"\f0da";
                                font-family: FontAwesome;
                                margin-right:11px;
                            }
                            padding:0.5em 1em;
                        }
                    }
                    
                }
            }
        }
    }
    .canli-destek{
        top:initial;
        width:initial;
        bottom:15px;
        position: fixed;
        .ikonlar {
            @include border-bottom-left-radius(0);
            @include border-top-right-radius(15px);
        }
        .baslik{
            width:80px;
            height: 82px;
            @include border-top-left-radius(15px);
            @include border-bottom-left-radius(15px);
            @include border-top-right-radius(0);
            background-color: $whatsapp-color;
            color:white;
        }
    }
    section.slider .swiper-slide .container{
        text-align: center;
    }

    // bizden haberler
    .bizden-haberler{

        .fotograf{
            height: 220px;
            img{
                min-width: 100%;
            }
        }
        .bh-slider{
            height: initial;
        }
        
        .swiper-button{
            
            
            &.swiper-button-next{
                margin-right:0px;
                margin-left: 20px;
            }
            &.swiper-button-prev{
                margin-left:0px;
                margin-right: 20px;
                
            }
        }
        .swiper-container{
            height: auto;
            padding-bottom:60px;
            .swiper-slide{
                height: auto;
            }
        }
        .swiper-pagination{
            
        }
    }

    footer{
        .blok-1{
    
            .beyaz-alan{
                > * {
                    border-right: none;
                    border-bottom: solid #eaeaea 1px;
                }
            }

            .lists{
                > * {
                    margin-bottom: 14px;
                }
            }
        }
        .blok-2{
    
    
            .logo{
                p{
                    padding-right: 0;
                }
            }
        }
    }


}

