$brand-green : #00a28d;
$brand-green-hover : #00ba9b;
$brand-green-dark : #008770;

// 00626a kapalı
// 00a28d açık

$brand-blue-dark : #008a77;
$brand-blue-dark-hover : #007061;
$brand-blue-light : #00a28d;
$brand-blue-light-hover : #008a77;
$brand-gray-light : #eee;
$brand-gray-light2 : #f2f2f2;


//$brand-green : #0eb37c;
//$brand-green-hover : #10cc8d;
//$brand-green-dark : #0c996a;
//
//
//$brand-blue-dark : #0c6e98;
//$brand-blue-dark-hover : #084a66;
//$brand-blue-light : #008abd;
//$brand-blue-light-hover : #009ed7;
//$brand-gray-light : #eee;
//$brand-gray-light2 : #f2f2f2;




// social media
$color-facebook  : #3B5998;
$color-facebook-hover  : #4668b3;
$color-twitter  : #55acee;
$color-twitter-hover  : #5cb8ff;
$color-instagram   : #e4405f;
$color-instagram-hover   : #fc4768;
$color-pinterest : #CC2127;
$color-youtube   : #E52D27;
$color-youtube-hover   : #ff322b;
$messenger-color : #0184ff;
$messenger-color-hover : #026acc;
$whatsapp-color : #0dc143;
$whatsapp-color-hover : #0a8f32;

// bacground-color

.bg-brand-blue-dark{
    background-color:$brand-blue-dark;
    &:hover{
        background-color: $brand-blue-dark-hover;
    }
}
.bg-brand-blue-light{
    background-color:$brand-blue-light;
}
.bg-brand-green{
    background-color: $brand-green;
    &:hover{
        background-color: $brand-green-hover;
    }
}
.bg-brand-gray-light{
    background-color:$brand-gray-light;
}
.bg-brand-gray-light-2{
    background-color:$brand-gray-light2;
}

// color

.text-brand-blue-dark{
    color:$brand-blue-dark;
}
.text-brand-blue-light{
    color:$brand-blue-light;
}
.text-brand-green{
    color:$brand-green;
}
.text-brand-gray-light{
    color:$brand-gray-light;
}
.text-brand-gray-light-2{
    color:$brand-gray-light2;
}
// link
a.text-brand-green{
    &:hover{
        color:$brand-green-dark;
    }
}

.btn-brand-green{
    background-color: $brand-green;
    color:#fff!important;
    
    &:active,
    &:focus,
    &:hover{
        background-color: $brand-green-hover;
        box-shadow:none;
    }
}

.btn-outline-brand-green{
    border-color: $brand-green;
    color:$brand-green!important;
    border:solid $brand-green 1px;
    
    &:active,
    &:focus,
    &:hover{
        background-color: $brand-green;
        border-color: $brand-green;
        color:white!important;
        box-shadow:none;
    }
}

.btn-brand-blue{
    background-color: $brand-blue-dark;
    color:#fff!important;
    
    &:active,
    &:focus,
    &:hover{
        background-color: $brand-blue-dark-hover;
        box-shadow:none;
    }
}

.btn-brand-blue-light{
    background-color: $brand-blue-light;
    color:#fff!important;
    
    &:active,
    &:focus,
    &:hover{
        background-color: $brand-blue-light-hover;
        box-shadow:none;
    }
}