@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "colors";
@import "flex";

$source-sans-pro : 'Source Sans Pro', sans-serif;
$roboto : 'Roboto', sans-serif;
$dosis : 'Dosis', sans-serif;

.font-source-sans-pro { font-family: $source-sans-pro ; letter-spacing: initial; }
.font-roboto { font-family: $roboto ;}
.font-dosis { font-family: $dosis ;}

html{
    min-height: 100%;
    position: relative;
    color:#5b6366;
}

img{
    max-width: 100%;
}
body{

    font-family:"Open Sans", Arial, sans-serif;
    color:#777;

    .sayfa-yukleniyor-katmani{
        position:fixed;
        z-index:99999;
        width:100%;
        height:100%;
        top:0; left:0;
        background-image: linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
        opacity:1;
        visibility:visible;
        transition:all 1s cubic-bezier(0.39, 0.575, 0.565, 1);

        div{
            color:$brand-green;
            transition:all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
            opacity:1;
        }
    }

    overflow-y:scroll;

    &.active{
        .sayfa-yukleniyor-katmani{
            opacity:0;
            visibility:hidden;
            div{
                
                opacity:0;
            }
        }
    }

    &.projeler-sayfasi{
        background-color: #eeeeee;
    }

    
}
strong{
}
p{
    
    font-size:16px;
}
a{
    &:hover{
    }
}
.form-control{
    font-family: $source-sans-pro;
}

section{
    .baslik{
        color:$brand-blue-light;
    }
    .buyuk-baslik{
        color:$brand-blue-light;
        font-size:45px;
        font-weight: 800;
    }
}
.list-unstyled{
    a{
        &:hover{
            text-decoration: none;
        }
    }
}
.anasayfa-ikonu{
    width:33px; height:33px;
    display: block;
    line-height: 33px;
    text-align:center;
    background-color: $brand-green;
    color:$white;
}


.menu1{
    > li{
        border-right:solid #e2e2e2 1px;
        padding:0 10px;
        line-height: 12px;
        
        &:last-child{
            border-right:none;
        }

        color: #808080;
        font-size:13px;
        font-weight: 300;

        &:hover{
            color:$brand-blue-dark;
        }

        a{
            color: #808080;
            font-size:13px;
            font-weight: 300;
            &:hover{
                color:$brand-blue-dark;
            }
        }

        .currency{


        }
    }
    
}

ul.sosyal-medya-ikonlari{
    margin:0 -4px;
    margin-right: 4px;
    li{
        margin:0 4px;
        a{
            width:30px;
            height:30px;
            line-height: 30px;
            text-align: center;
            
            @include border-radius(30px);
            font-size:14px;
            
            display: block;
            background-color:#e5e5e5;
            color:#808080;

            transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
            

            &:hover{
                color:white;
            }
        }


        &.facebook{
            a{
                &:hover{
                    background-color: $color-facebook;
                }
            }
        }
        &.instagram{
            a{
                &:hover{
                    background-color: $color-instagram;
                }
            }
        }
        &.youtube{
            a{
                &:hover{
                    background-color: $color-youtube;
                }
            }
        }
    }
}

// arama

.arama {
    .arama-butonu{
        padding:1rem 10px;
        margin-bottom: 0;
        margin-right: 10px;
        margin-left: 5px;
        &:hover{
            cursor:pointer;
        }
        i{
            color:#808080;
            font-size:16px;
        }
    }
    .arama-kutusu{
        position: absolute;
        top:0.5rem; 
        right:0;
        width: 250%;
        opacity: 0;
        visibility: hidden;
        transition:all 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);

        &.active{
            visibility: visible;
            opacity: 1;
        }

        input{
        }
    }
}

// dil seçenekleri 

ul.dil-secenekleri{
    li{
        &:first-child{
            a{
                @include border-top-left-radius(30px);
                @include border-bottom-left-radius(30px);
            }
        }
        &:last-child{
            a{
                @include border-top-right-radius(30px);
                @include border-bottom-right-radius(30px);
            }
        }
        a{
            height: 30px;
            line-height: 32px;
            font-size:11px;
            font-weight: bold;
            font-family: $roboto;
            padding:0px 9px;
            display: block;
            background-color:#e6e6e6;
            color:#808080;
            &:hover{
                background-color:#ddd;
            }
        }
        &.active {
            a{
                background-color: $brand-green;
                color:white;
                &:hover{
                    background-color: $brand-green-hover;
                }
            }
        }
    }
}

// header blok 2 : logo, slide text, bağış yap 
.header-blok-2{
    @include gradient-y(#e5e5e5, #ffffff);
    .row > *{
        height: 140px;

        

        #typewriter{
            font-size: 25px;
            font-weight: 100;
            font-family: $source-sans-pro;
            color:#808080;
            letter-spacing:2pt;
        }
        
    }
}

.custom-dropdown{
    position:relative;
    display:inline-block;
    .list{
        position:absolute;
        visibility:hidden;
        width:100%;
        top:100%;
        left:0;
        z-index:13;
         
        > * {
            display:block;
        }

        &.hide{
            visibility: hidden;
        }
    }

    &:hover{
        .list{
            visibility:visible;
        }
    }

}

.doviz{
    //background-color:$brand-green;
    //color:white; 
    cursor:pointer;
    .list{
       
        color:#666;
        > * {
            a{
                background-color:#828282;
                color:white;
                display:block;
                height:25px;
                line-height:25px;
                padding:0px 7px;
                text-align:left;
                text-decoration: none;
                font-size: 11px;
                font-weight: bold;
                font-family: "Roboto",sans-serif;
                
                padding: 0px 7px; 
            }
            &:hover a{
                background-color:$brand-green;
                color:white;
            }
        }
    }
}
.doviz-btn{
    height: 25px;font-size: 11px;
    font-weight: bold;
    font-family: "Roboto",sans-serif;
    line-height: 25px;
    padding: 0px 7px; background-color:#00a28d; color:white;
}

.bagis-yap-bolumu-blok{
    a.hesap-no-link{
        color:#808080;
        font-size:13px;
        font-family: $source-sans-pro;
        font-weight: 500;
        &:hover{
            text-decoration: none;
            color: $brand-blue-dark;
        }
    }

    .bagis-yap-bolumu{
        position: relative;
        z-index:20;

        &:hover{
            .bagis-yap-menusu{
                opacity: 1;
                visibility: visible;
            }
        }
        button{
            font-size:14px;
            font-weight: 500;
            letter-spacing: 0.7px;
        }
        .bagis-yap-menusu{
            position: absolute;
            z-index:21;
            width: 288px;
            top:100%;
            right:0;
            padding-top:10px;

            opacity: 0;
            visibility: hidden;

            transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
            
            > * {
                
                background-color: $brand-blue-light;
                padding-bottom:10px;
                @include border-radius(5px);

                box-shadow:($box-shadow);

                >*{
                    width:calc(50% - 15px);
                    
                    margin-top:10px;
                    margin-left:10px;

                    padding:20px 0;

                    text-align: center;
                    color:#fff;
                    font-size:13px;
                    font-weight: 500;

                    border:solid rgba(255,255,255,0.15) 1px;
                    transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

                    img{
                        min-width: initial;
                    }
                    > * {
                        &:nth-child(2){
                            margin-top:10px;
                        }

                    }

                    &:hover{
                        border-color:transparent;
                        background-color: rgba(0,0,0,0.15);
                        text-decoration: none;
                    }

                }
            }
            
        }
    }

    .sepet-ikonu{
        position: relative;
        display:block;
        z-index:20;
        color:#666;
        
        img{
            transition:all 0.2s ease-in-out;
        }

        &.active,
        &:hover{

           
            .sepet-list{
                visibility: visible;
                opacity: 1;
            }
        }

        .siparis-adeti{
            position: absolute;
            z-index: 1;
            top:3px;
            left:12px;
            font-size:11px;
            font-family: $roboto;
            color:#828282;
            font-weight: bold;
        }

        .sepet-list{
            position: absolute;
            top:100%;
            padding-top:10px;
            right:0;
            width:300px;
            z-index: 10;
            transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
            opacity: 0;
            visibility: hidden;

            > *{
                background-color:white;
                box-shadow:($box-shadow);
                border-radius: 5px;
                box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
            }
            
            
        
            .bagis-listesi{
                margin:0 0rem;
                > * {
                    padding:0.5rem 1rem;
                    @include flexbox();
                    @include align-items(center);
                    border-bottom:solid #eee 1px;
    
    
                    i{
                        margin-right:7px;
                        font-size:18px;
                        cursor: pointer;
                        &:hover{
                            color:$color-instagram;
                        }
                    }
                    .isim{
                        margin-right:auto;
                    }
                    .fiyat{
                        color:$brand-green;
                    }
                }
            }

            .toplam{
                @include flexbox();
                @include align-items(center);
                margin:0 0rem;
                background-color:#eee;
                padding:0.5rem 1rem;
                .isim{
                    margin-right:auto;
                    font-weight: 500;
                }
                .fiyat{
                    color:$brand-green;
                }
            }

            a{
                color:#777;
            }

            .btn{
                background-color:#a99b5c;
                border-color:#7d7347;
                color:white;
                border-width:2px;
                position: relative;
                //bottom:calc(-1rem - 25px);
                padding:0 2rem;
                //margin-top:-1rem;
                font-weight: 500;
                font-size:16px;
                line-height: 50px;
                margin:1em 0;

                i{
                    margin-right:0.5rem;
                }
    
                &:focus,
                &:active{
                    outline:none;
                    box-shadow:none;
                }
                &:active{
                    background-color:#c2b169;
                }
            }
        }

    }
}

// ana menü
nav.ana-menu{
    background-color: $brand-blue-light;
    //border-bottom:solid $brand-blue-dark 6px;
    //height:54px;
    font-family: $dosis;

    .container > ul{
        position: relative;
        
        margin:0;
        > li{
            //border-bottom:solid $brand-blue-dark 6px ;
            &:hover{
                >a{
                    background-color:$brand-blue-light-hover;
                }
                ul{
                    visibility: visible;
                    opacity: 1;
                }
            }
            &.active{
                > a {
                    background-color: $brand-blue-light-hover;
                }
            }

            > a{
                display: block;
                line-height: 48px;
                color:white;
                font-size:15px;
                padding:0 20px;

                font-weight: 500;
                letter-spacing: 0.3px;
                i{
                    margin-left:5px;
                }
            }
            > ul{
                min-width:230px;
                position: absolute;
                z-index: 10;
                top:100%;
                list-style-type: none;
                margin:0;
                padding:0;
                background-color:#f8f8f8;
                box-shadow: $box-shadow;
                transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
                opacity:0;
                visibility: hidden;

                li{
                    &:hover{
                        a{
                            background-color: $brand-blue-light;
                            color:white;
                        }
                    }
                    a{
                        display:block;
                        font-size:15px;
                        color:#666666;
                        font-weight: 400;
                        padding:5px 20px;
                    }
                }
            }
        }
    }
}

// canlı destek 

.canli-destek{
    width:80px;
    position: fixed;
    z-index: 21;
    bottom:15px;
    right:15px;
    font-family: $source-sans-pro;
    letter-spacing: initial;

    @include border-radius(15px);
    box-shadow:($box-shadow);

    transition:all 0.2s ease-out;
    

    &.active{
    }

    .baslik{
        color:#4f4f4f;
        text-align: center;
        background-color:white;
        font-size:16px;
        font-weight: 500;
        line-height: 16px;
        padding:15px 0;
        @include border-top-left-radius(15px);
        @include border-top-right-radius(15px);
    }

    .ikonlar{
        @include border-bottom-left-radius(15px);
        @include border-bottom-right-radius(15px);
    }
    
    
    a{
        width:50px;
        height: 50px;
        display:block;

        @include border-radius(50px);
        padding:12px;
        transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

        &.messenger{
            background-color: $messenger-color;
            &:hover{
                background-color: $messenger-color-hover;
            }
        }
        &.whatsapp{
            background-color: $whatsapp-color;
            &:hover{
                background-color: $whatsapp-color-hover;
            }
        }
    }
    
}

// home slider

.slider{
    position: relative;
    
    .swiper-progress{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.2);
        height: 10px;
        z-index: 11;
        width:1%;
        transition-property:all;
        transition-timing-function: ease-out;

        &.active{
            width:100%;
        }
    }
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    
    .swiper-slide {
        font-size: 18px;
        background: #fff;
        font-family: $dosis;
        color:#fff;
        
        height:600px;
        
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        
        .container{
            position: relative;
            z-index: 1;
        
        }

        &.swiper-slide-active{
            
            .baslik{
                opacity:1;
                visibility: visible;
                transform:translate(0,0px);

            }
            .birinci-paragraf{
                opacity:1;
                visibility: visible;
                transform:translate(0,0px);
                
                
                &:after{
                    width:100%;
                }
            }
            
            /* &:after{
                
                opacity: 1;
            } */
        }
        
        /* &:after{
            content:'';
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            opacity:0;
            transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1);
            transition-delay: 0.5s;
            @include gradient-y(transparent, #000000);
            
        } */
        
        .baslik{
            font-size:52px;
            font-weight:bold;
            line-height: 65px;
            color:white;
            
            transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
            opacity: 0;
            visibility: hidden;
            transition-delay: 1.5s;
            transform:translate(0,-100px);
        }
        .birinci-paragraf{
            font-size:38px;
            font-weight:500;
            display: inline-flex;
            position: relative;
            
            
            opacity: 0;
            visibility: hidden;
            
            transform:translate(0,100px);
            transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
            transition-delay: 1.7s;
            
            &:after{
                
                content:'';
                position: absolute;
                width:1%;
                height:5px;
                bottom:-10px;
                background-color:#fff;
                border-radius: 3px;   
            transition-property: width; 
            transition-delay:1.7s;
            transition-duration: 2s;
            transition-timing-function: ease;
            
            
        }
    }
}

.swiper-pagination{
    bottom:60px!important;
    .swiper-pagination-bullet{
        border:solid #fff 1px;
        border-radius: 0;
        width:15px;
        height:15px;
        &.swiper-pagination-bullet-active{
            background-color:$brand-blue-light;
            border-color:$brand-blue-light;
        }
    }
}
.swiper-button{
    width:44px;
    height:44px;
    border:solid $brand-blue-light 2px;
    background-color: $brand-blue-light;
    outline:none;
    
    &:after{
        font-family: FontAwesome!important;
        font-size:32px;
        color:#fff;
        content:'\f104';
    }
    
    
    &.swiper-button-next{
        right:32px;
        &:after{
            content:'\f105';
        }
    }
    &.swiper-button-prev{
        
        
        left:32px;
        &:after{
            content:'\f104';
        }
    }
    &.swiper-button-disabled{
    }
    
}

}
.slider-menu{
    background-color: $brand-blue-dark;

    .lists{
        height:175px;
        > * {
            cursor: pointer;
            flex: 1 0;
            display: -ms-flexbox;
            display: flex;
                    
            -ms-flex-align: center;
            align-items: center;

            -ms-flex-pack: center;
            justify-content: center;

            transition:all 0.5s ease;

            padding:0 1em;
            text-align:center;
            text-decoration:none;


            &:hover{
                background-color: $brand-blue-dark-hover;
                .icon{
                    background-color:white;
                    img{
                        filter:brightness(1);
                    }
                }
            }

            
            .icon{
                width:75px;
                height:75px;
                line-height: 75px;
                border-radius: 75px;
                background-color:$brand-blue-light;
                
                margin:auto;
                transition:all 0.5s ease;

                img {
                    transition:all 0.5s ease;
                    filter:brightness(0) invert(1);
                    min-width: initial;
                }
            }
            .baslik{
                margin-top: 1em;
                color:white;
                font-size:14px;
                font-weight: 500;
                font-family: $dosis;
            }
        }
    }
}

// anasayfa bağış yap bölümü

.anasayfa-bagis-bolumu{
    padding:1em 0;
    background-color: white;

    .lists {
        .swiper-slide {
            &:hover{
                .fotograf{

                    .blok-1{
                        .tutar{
                            padding-bottom: 2em;
                        }
                        .slogan{
                            visibility: visible;
                            opacity: 1;
                            transform:translate(0,-24px);
                            transition-delay:0.1s;
                            white-space: nowrap;
                            
                        }
                    }
                }
            }
        }
    }
    img{
        max-width: 100%;
        min-width: 100%;
    }
    .fotograf{
        position: relative;
        overflow: hidden;

        

        .blok-1{
            padding:0.2em 0;
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            background-color: rgba(255,255,255,0.8);
            text-align: center;

            .tutar{
                font-family: $roboto;
                font-size:24px;
                transition:all 0.4s ease;
                color: $brand-blue-light;
            }
            .slogan{
                opacity: 0;
                line-height: 0;
                visibility: hidden;
                transition:all 0.4s ease;
                transform:translate(0,100%);
                transition-delay:0s;
            }
        }
    }
    .blok-2{
        background-color: #ececec;
        padding-bottom: 1em;
        .baslik{
            padding:0.5em 0;
            text-align: center;
            font-size:22px;
            color:#353636;
            font-weight: bold;
            font-family: $dosis;
        }
    }

    .swiper-container {
        width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-slide {
  
        /* Center slide text vertically */

      
    }
    .swiper-button-prev,
    .swiper-button-next{
        position:relative;
        height:initial;
        width:initial;
        top:initial;
        left:initial;
        right:initial;
        outline:none;
        margin:initial;
    }
    .swiper-button-prev{
    }.swiper-button-next{
    }

    .swiper-button{
        width:20px;
        outline:none;
        margin-bottom:60px;
        
        &:after{
            font-family: FontAwesome!important;
            font-size:52px;
            color:$brand-blue-dark;
            content:'\f104';
        }
        
        
        &.swiper-button-next{
            margin-right:-60px;
            &:after{
                content:'\f105';
            }
        }
        &.swiper-button-prev{
            margin-left:-60px;
            &:after{
                content:'\f104';
            }
        }
        &.swiper-button-disabled{
        }
        
    }
    .swiper-pagination{
        bottom:0;

        .swiper-pagination-bullet{
            width:15px;
            height:15px;
            
            border-radius:0!important;

            background-color:#4e585c;
            outline:none;

            opacity:1;


            &.swiper-pagination-bullet-active{
                background-color:white;
            }
        }
    }
}

// sayaç

.sayac{
    color: $brand-blue-light;
    position:relative;
    i{
        cursor: pointer;
    }
    .adet{
        font-size:24px;
        
    }
}
// bizden haberler

.bizden-haberler{
    //background-image:url(../images/patterns/dark-grey-terrazzo.png);
    background-color:whitesmoke;

    .baslik-1{
        font-family: $dosis;
        font-size:32px;
        color:gray;
        font-weight: 500;
    }
    .tumunu-gor a{
        color:gray;
        font-size:16px;
        &:hover{
        }
    }

    .satir{
        font-family: $dosis;

        &:hover{
            text-decoration: none;

            .yazilar{
                > * {
                    background-color: $brand-blue-light;
                    color: white;
                }
                .aciklama{
                    
                }
            }

            .fotograf{
                
                .devamini-oku{

                    opacity: 1;
                    visibility: visible;
                    transform:translate(0,0);
                    text-decoration:none;
                    
                }
            }
        }

        .fotograf{
            
            > * {
                width: 100%;
                position: relative;
                overflow: hidden;
                background-size:cover;
            }

            img{
                min-width:100%;
            }
            .devamini-oku{
                position: absolute;
                z-index:2;
                left:0; right:0;
                bottom:15px; 
                padding:10px 15px;  
                text-align: center;
                
                background-color: #808080;
                margin:0 15px;
                color:#fff;
                font-weight: 600;
                font-size: 13px;

                i{
                    margin-left:5px;
                }
                opacity: 0;
                visibility: hidden;
                transform:translate(0,100%);
                transition:all 0.3s ease;

                &:hover{
                    background-color: $brand-blue-light;
                    color:white;                
                }
            }
        }

        .yazilar{
            transition:all 0.4s ease;
            > * {
                height:100%;
                padding:1.5em;
                
                transition:all 0.4s ease;
                background-color:white;
                color:#808080;

            }

            .baslik{
                font-size:24px;
                margin-bottom:0.2em;
                padding-right: 20%;
            }
            .tarih{
                font-size:14px;
                font-weight: 500;
            }
            .aciklama{
                padding-top:1em;
                font-size:16px;
                line-height: 19px;
                min-height: 80px;
            }
        }
    }

    .swiper-container {
        width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-slide {
        height: auto;
  
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

      
    }
    .bh-slider{
        height: 460px;
    }

    .swiper-button-prev,
    .swiper-button-next{
        position:relative;
        height:initial;
        width:initial;
        top:initial;
        left:initial;
        right:initial;
        outline:none;
        margin:initial;
    }
    .swiper-button-prev{
    }.swiper-button-next{
    }

    .swiper-button{
        width:20px;
        outline:none;
        margin-bottom:60px;
        
        &:after{
            font-family: FontAwesome!important;
            font-size:52px;
            color:$brand-green;
            content:'\f104';
        }
        
        
        &.swiper-button-next{
            margin-right:-60px;
            &:after{
                content:'\f105';
            }
        }
        &.swiper-button-prev{
            margin-left:-60px;
            &:after{
                content:'\f104';
            }
        }
        &.swiper-button-disabled{
        }
        
    }
    .swiper-pagination{
        bottom:0;

        .swiper-pagination-bullet{
            width:15px;
            height:15px;
            border-radius:0!important;

            background-color:gainsboro;
            outline:none;

            opacity:1;

            &.swiper-pagination-bullet-active{
                background-color:$brand-green;
            }
        }
    }

}

// medya galeri 
.medya-galeri{
    background-color: white;
    .baslik{
        color:$brand-blue-light;
    }

    .lists{
        > * {
            padding:0;
            margin:0;
            width:240px;
            background-color:#ccc;
            position: relative;
            background-color: rgba(0,0,0,0.6);

            a{
                display: block;
                color:white;
                
                &:hover{
                    text-decoration: none;
                    &:before{
                        background-color:rgba(0,0,0,0);
                    }
                    &:after{
                        transform:scale(1.5);
                    }
                }

                &:before{
                    content:'';
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    z-index: 2;
                    background-color:rgba(0,0,0,0.35);
                    transition:all 0.5s ease;
                }

                img{
                    max-width:100%;
                    max-height:100%;
                }

                &.video{
                    &:after{
                        content:'\f01d';
                        font-family: FontAwesome;
                        position: absolute;
                        z-index: 1;
                        font-size:42px;
                        display: flex;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        transition:all 0.3s ease;
                    }
                }
            }
        }
    }
    
}

// ödeme yöntemleri
.odeme-yontemleri{
    background-color: white;
    .lists{
        a{
            display: block;
            text-decoration: none;
            color:#666666;
            font-size:18px;
            font-weight: 500;
            font-family: $roboto;
            margin-bottom: 60px;

            i{
                display: -ms-flexbox;
                display:flex;
                
                align-items: center;
                justify-content: center;
                border:solid #bfbfbf 1px;
                width:120px;
                height:120px;
                font-size:42px;
                margin:auto;
                color:#666666;
                margin-bottom: 15px;
                transition: all 0.3s ease;
            }

            &:hover{
                color:$brand-blue-light;
                i{
                    background-color:$brand-blue-light;
                    border-color:$brand-blue-light;
                    color:white;
                }
            }
        }
    }
}



footer{
    font-weight: 400;
    letter-spacing: initial;
    z-index: 5;
    position: relative;

    .blok-1{
        
        background-color:$brand-blue-dark;
        text-align: center;

        .container{
            top: -56px;
            position: relative;
            margin-bottom: -56px;
        }

        .beyaz-alan{
            background-color:white;
            box-shadow: 0px -6px 32px rgba(40,40,40,0.15);
            border-radius: 15px;
            
            > * {
                
                border-right:solid #eaeaea 1px;
                padding:15px 2em;

                &:nth-child(1){
                    font-size:19px;
                    font-family: $source-sans-pro;
                    a{
                        color:$brand-blue-dark;
                        font-weight: 500;
                        display: block;
                        line-height: 24px;
                    }
                }

                &:nth-child(2){
                    a{
                        color:#7d7d7d;
                        @include flexbox;
                        @include align-items(center);
                        font-size:21px;
                        padding:0 14%;
                        font-weight: 500;
                        font-family: $roboto;
                        text-decoration: none;
                        transition:all 0.3s ease;

                        &:hover{
                            color: $brand-green;
                        }

                        i{
                            font-size:49px;
                        }
                    }
                }

                &:nth-child(3){
                    button{
                        width:45px;
                        height:40px;
                        i{
                            font-size:14px;
                        }

                    }
                    input[type="email"]{
                        background-color:#f1f1f1;
                        border:none;
                        font-size:13px;
                        height: 40px;
                    }
                }


                &:last-child{
                    border:none;
                }
            }
        }
        .lists{
            color:white;
            padding: 14px 0;
            margin-right: -7px;
            margin-left: -7px;

            > * {

                height:134px;
                padding:0 7px;
                
                
                a{
                    background-color:$brand-blue-dark;
                    width: 100%;
                    height: 100%;
                    padding: 0 14%;

                    @include flexbox;
                    @include align-items(center);
                    @include justify-content(center);
                    color:white;
                    text-decoration: none;
                    transition:background-color 0.5s ease-out;
                    &:hover{
                        background-color:$brand-blue-dark-hover;
                        @include box-shadow($box-shadow-lg);
                    }

                }
                i{
                    margin-right: 20px;
                    font-size:49px;
                    &.fa-database{
                        font-size:41px;
                    }
                }
                span{
                    font-size:21px;
                    font-weight: 500;
                }
            }
        }
    }
    .blok-2{
        font-family: $roboto;

        @include gradient-y(#ffffff, #d3dcdf);


        .logo{
            img{
                min-width: initial;
                max-width: 250px;
                
            }
            p{
                font-size:13px; line-height:14px; color:#999;
                padding-right: 20%;
            }
        }

        .baslik{
            font-size:15px;
            font-weight: 500;
        }
        .lists{
            margin-top:20px;
            font-size:13px;

            a{
                display: block;
                padding:4px 0;
                color:#666;
                transition:all 0.3s ease;
                
                i{
                    padding:3px 0px 0 0;
                    text-decoration: none;
                    font-size:14px;
                    width:14px;
                    text-align: center;
                    margin-right:10px;
                }

                &:hover{
                    color:$brand-blue-light;
                
                    &:after{
                        left:0;
                        opacity: 1;
                        visibility: visible;
                        color:$brand-blue-light;
                    }
                }

                &:after{
                    content:'\f105';
                    font-family: FontAwesome;
                    font-size:14px;
                    position: absolute;
                    left:-10px;
                    transition:all 0.3s ease;
                    opacity: 0;
                    visibility: hidden;

                }
            }
        }
        ul.sosyal-medya-ikonlari{
            li{
                a{
                    background-color:#8fbacc;
                    color:#fff;
                    
                    &:hover{                        
                        background-color:$brand-blue-light!important;
                    }
                }
        
            }
        }

    }
    .blok-3{
        border-top:solid 6px $brand-blue-light;
        background-color:$brand-blue-dark;
        color:white;
        font-size:13px;
        font-family: $roboto;
        font-weight: 400;
        a{
            color:white;
        }
    }
}

.yan-menu{
    background-color:#e4edf0;
    @include border-radius(10px);
    .baslik{
        background-color:$brand-blue-dark;
        font-family: $dosis;
        color:white;
        font-size:16px;
        font-weight: 500;
        text-align: center;
        padding:10px;
        @include border-top-left-radius(10px);
        @include border-top-right-radius(10px);
    }
    ul.lists{
        padding:10px 20px;
        li{
            border-bottom: solid #d3dbde 1px;

            &.active{
                a{
                    color:$brand-blue-light;
                    padding-left:15px;
                    &:after{
                        right:initial;
                        left:0;
                        opacity: 1;
                        visibility: visible;
                        color:$brand-blue-light;
                    }
                }
            }

            a{
                color:#6b6f70;
                display: block;
                padding:10px 0 ;
                font-family: $source-sans-pro;
                font-weight: 500;
                font-size: 16px;
                position: relative;

                &:hover{
                    color:$brand-blue-light;
                
                    &:after{
                        right:0;
                        opacity: 1;
                        visibility: visible;
                        color:$brand-blue-light;
                    }
                }

                &:after{
                    content:'\f0da';
                    font-family: FontAwesome;
                    font-size:16px;
                    position: absolute;
                    right:20px;
                    transition:all 0.3s ease;
                    opacity: 0;
                    visibility: hidden;

                }

            }
            &:last-child{
                border:none;
            }
        }
    }
}

.projeler{

    
    .lists{
        > * {
            > * {

                background-color:white;
                padding:30px;
                margin-bottom: 60px;
                box-shadow: 0px 15px 15px rgba(0,0,0,0.10);
                color:#444444;
                
                .baslik{
                    font-size:24px;
                    font-weight: 500;
                    padding-bottom: 15px;
                }
                p.aciklama{
                    font-size:15px;
                    line-height: 18px;
                    padding:15px 0;
                    color:#777;
                    font-weight: 500;
                }
                img{
                    min-width: 100%;
                    
                }
                a{
                    @include border-radius(20px);
                    background-color:$brand-blue-light;
                    padding:15px 50px;
                    font-size:14px;
                    font-weight: 800;
                    line-height: 14px;
                    color:white;
                    @include inline-flex;
                    text-decoration: none;
                    &:hover{
                        background-color:$brand-blue-light-hover;
                        box-shadow:($box-shadow);
                        
                    }
                }

            }
        }
    }


    
}

.sikca-sorulan-sorular{
    .accordion{
        .card{
            border:none!important;
            margin-bottom: 5px;
            .card-header{
                padding:0;
                background: none!important;
                .btn{
                    color:$brand-green;
                    text-decoration: none;
                    font-weight: 500;
                    width:100%;
                    text-align: left;
                    padding:0;
                    font-size:18px;
                    
                    &:before{
                        width:15px;
                        content: '\f054';
                        font-family: FontAwesome;
                        position: relative;
                        margin-left: 5px;
                        margin-right: 15px;
                        display:inline-flex;
                        
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                        -webkit-transition: all 0.3s;
                        -o-transition: all 0.3s;
                        transition: all 0.3s;
                    }
                    &.collapsed{

                        &:before{
                            transform:rotate(0deg);
                        }
                    }
                }
                border:none!important;
            }
            .card-body{
                //background-color:#f6f6f6;
                padding:15px 0 0px 0;

            }
            .collapse{
            }
        }
    }
}

.proje-detay{

    

    background-color:$brand-gray-light;
    h2{
        border-bottom:solid #eee 1px;
        font-weight: 400;
        font-size:34px;
    }
    .h3,
    h3{
        padding: 15px 0;
        font-weight: 400;
        font-size:26px;
    }

    .accordion{
        .card{
            border:none!important;
            margin-bottom: 5px;
            .card-header{
                padding:0;
                .btn{
                    text-decoration: none;
                    font-weight: 500;
                    color:$brand-blue-light;
                    width:100%;
                    text-align: left;

                    &:before{
                        width:15px;
                        content: '\f068';
                        font-family: FontAwesome;
                        position: relative;
                        margin-left: 5px;
                        margin-right: 15px;
        
                    }
                    &.collapsed{
                        color:#666;

                        &:before{
                            content: '\f067';
                        }
                    }
                }
                border:none!important;
            }
            .card-body{
                background-color:#f6f6f6;
                padding:15px 50px 35px 50px;

            }
            .collapse{
            }
        }
    }
}


.bagis-yap-1{
    .input-group{
        width:initial;
        input{
            width:80px;
            height: 43px;

            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            text-align: center;
            font-weight: 500;
            color:#777;
            font-size:16px;
        }
        .input-group-text{
            font-size:16px;
            font-weight: 500;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            width:45px;
            height:43px;
            color:#777;
            font-weight: 600;
        }
    }
    .btn{
        font-weight: 700;
        font-size:14px;
        padding:10px 20px;
        border-radius: ($border-radius);
        height: 43px;
    }
}

.bagis-yap-2{
    .lists{
        > * {
            > * {
                transition:all .4s ease;
                &:hover{
                }
            }
        }
        a{
            position: relative;
            display: block;
            overflow: hidden;

            &:before{
                                
                content:'';
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                z-index: 1;
                background-color:rgba(255,255,255,0.6);
                transition:all 0.5s ease;
                opacity: 0;
            
            }
            &:after{
                content:'\f0c1';
                font-family: FontAwesome;
                position: absolute;
                color:$brand-blue-light;
                z-index: 2;
                font-size:42px;
                display: flex;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                transition:all 0.3s ease;
                opacity: 0;
            }

            &:hover{
                text-decoration: none;
                &:before{
                    opacity: 1;                
                }
                &:after{
                    transform:scale(1.5);
                    opacity: 1;
                }
            }
        }
        .baslik{
            font-weight: 600;
            font-size:18px;
            color:#444;
            margin-bottom: 10px;
        }

        p{
            height:40px;
            overflow: hidden;
            font-size:13px;
            font-weight: 400;
            line-height: 1.4;
            margin-bottom:0;
        }
        .yazi{
            background-color:#f8f8f6;
        }
        .bagis-yap-1{
            margin:0;
            padding:0;
            
            .input-group input{
                font-size:15px;
                height: 40px;
                border-top-left-radius: ($border-radius);
                border-bottom-left-radius: ($border-radius);
                color:#444;
                font-weight: 600;
                border-right: none;
                &:focus,
                &:active{
                    box-shadow: none;
                    border-color:($border-color);
                }
            }
            .input-group-text{
                height: 40px;
                border-top-right-radius: ($border-radius);
                border-bottom-right-radius: ($border-radius);
                background-color:#f3f2ee;
                font-size:14px;
                width:40px;
            }
            .btn{
                padding: 10px 15px;
                font-size:14px;
                font-weight: 400;
                height: 40px;
                white-space: nowrap;
                //font-family: $dosis;
            }
        }
    }
}

.paylas{
    .baslik{
        font-weight: 500;
        color:#666;
    }
    ul{
        li{
            a{
                i{
                    color:#fff;

                    width:32px;
                    height:32px;
                    text-align: center;
                    font-size:18px;
                    line-height: 32px;
                    @include border-radius(16px);
                }

                i.fa-facebook{
                    background-color:#3a5795;
                }

                i.fa-twitter{
                    background-color:#55acee;
                }

                i.fa-instagram{
                    background-color:$color-instagram;
                }

                &:hover{
                    i{
                        box-shadow: ($box-shadow);
                    }
                    i.fa-facebook{
                        background-color:$color-facebook-hover;
                    }
                    i.fa-twitter{
                        background-color:$color-twitter-hover;
                    }
                    i.fa-instagram{
                        background-color:$color-instagram-hover;
                    }
                }
            }
        }

    }

}

.proje-haberleri{
    .baslik{
        font-size:18px;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color:#777;
    }
    p{
        font-size:14px;
    }
}

.bagis-sepeti-yanda{
    > .baslik{
        background-color:$brand-green;
        color:white;
        text-align: center;
        @include border-top-left-radius(5px);
        @include border-top-right-radius(5px);
        padding:10px 0 ;
        font-weight: 500;
    }
    .icerik{
        background-color: #f8f8f6;
        padding:1rem;
        
        .sepet-bos{
            min-height:200px;
            text-align:center;
            font-weight: 500;
        }

        .bagis-listesi{
            margin:0 -1rem;
            > * {
                padding:0.5rem 1rem;
                @include flexbox();
                @include align-items(center);
                border-bottom:solid #eee 1px;


                i{
                    margin-right:7px;
                    font-size:18px;
                    cursor: pointer;
                    color:#777;
                    &:hover{
                        color:$color-instagram;
                    }
                }
                .isim{
                    margin-right:auto;
                }
                .fiyat{
                    color:$brand-green;
                }
            }
        }
        .toplam{
            @include flexbox();
            @include align-items(center);
            margin:0 -1rem;
            background-color:#eee;
            padding:0.5rem 1rem;
            .isim{
                margin-right:auto;
                font-weight: 500;
            }
            .fiyat{
                color:$brand-green;
            }
        }
    }
    
    .bagisci-bilgileri{
        border:solid $brand-green 2px;
        border-radius: 10px;
        background-color: #f8f8f6;
        padding:1rem;
        margin-top:1.5rem;
        position: relative;
        padding-top:1.5rem;

        select{
            padding:0 0.25rem;
        }
        .baslik{
            @include inline-flex();
            color:white;
            font-size:14px;
            padding:0px 12px;
            top:-13px;
            height: 26px;
            line-height: 26px;
            position: absolute;

            background-color:$brand-green;
            border-radius: 15px;

        }
        .baslik-2{
            font-weight: 500;
            font-size:14px;
            margin-top:8px;
            margin-bottom:4px;
        }
    }
    .kredi-karti-bilgileri{
        margin-top:1.5rem;
        padding:1rem;
        border-radius: 0.5rem;
        background-color:$brand-green;
        color:white;
        margin-bottom: 3rem;
        label{
            font-size:14px;
            display:block;
            padding:4px 0;
            margin:0;
        }
        input{
            padding:0 0.5rem;
        }
        select,
        input{
            border:none;
            
        }
        select{
            padding:0 0.25rem;
        }

        button{
            background-color:#a99b5c;
            border-color:#7d7347;
            color:white;
            border-width:2px;
            position: relative;
            bottom:calc(-1rem - 25px);
            padding:0 1rem;
            margin-top:-1rem;
            font-weight: 500;
            font-size:16px;
            height:50px;
            i{
                margin-right:0.5rem;
            }

            &:focus,
            &:active{
                outline:none;
                box-shadow:none;
            }
            &:active{
                background-color:#c2b169;
            }
        }
    }
}
.bagis-yap-3{
    border-radius:10px;
    background-color: $brand-green-dark;
    .yazi{
        p{
            margin:0;
            padding:0;
            font-size:16px;

            &:nth-child(1){
                font-weight: 300;
                color:#afdece;
            }
            &:nth-child(2){
                color:#fff;
                font-weight: 500;
            }
        }
    }

    .bagis-yap-1{
        .input-group input{
            width:60px;
            font-size:14px;
            height: 40px;
            border:none;
        }
        .input-group-text{
            height: 40px;
            border:none;
        }
        .btn{
            padding: 10px 15px;
            font-size:12px;
            height: 40px;
            white-space: nowrap;

            //font-family: $dosis;
        }
    }

}

#bagisTab.nav-tabs{
    .nav-item{
        margin-right: -1px;

        &:first-child{
            .nav-link{
                border-top-left-radius: ($border-radius-lg);
            }
        }
        &:last-child{
            .nav-link{
                border-top-right-radius: ($border-radius-lg);
            }
        }

        .nav-link{
            border-radius: 0;
            
            color: #777;
            background-color: #f8f8f6;
            border-color: #dee2e6;
            padding:9px 22px;
            font-weight: 500;

            &.active{
                background-color:$brand-green;
                color:white;
                border-color: $brand-green;
                position: relative;
                z-index: 2;
                
            }


        }
    }
}

#bagisTabContent{
    background-color: #f8f8f6;
    border:solid #dee2e6 1px;
    border-top:none;
    padding:0 0.5em;
}

.subelerimiz{
    .lists{
        margin: 0 -0.5em;
        > * {
            margin-bottom:1em;
            padding:0 0.5em;
            font-size: 13px;
            color: #444;
            .il-ilce{
                span{
                    font-size: 20px;
                    font-weight: 600;
                    color: #444;
                }
            }
            a{
                color: $brand-blue-light;
            }
        }
    }
}

form{
    label{
        font-weight: 500;
        font-size:14px;
    }
}

.overlay{
    opacity: 0;
    visibility: hidden;
    width:100%;
    height:100%;
    top:0;
    left:0;
    position:fixed;
    z-index:19;
    transition:all 0.4s ease-in-out;
    background-color:rgba(0,0,0,0.6);

    &.active{
        opacity: 1;
        visibility: visible;
    }
}

.cizgili-baslik{

    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top:1.5rem ;
    margin-bottom:1.5rem ;
    &:before,
    &:after{
        position: relative;
        content: '';
        width:50px;
        height: 2px;
        background-color:$brand-gray-light;
    }
    &:before{
        margin-right: 1rem;
    }
    &:after{
        margin-left: 1rem;
    }
}

/*
.form-overlay{
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    text-align: center;
    padding:0 10%;
    font-weight: 600;

    &.active{
        opacity: 1;
        visibility: visible;
    }
}
*/
.abonelik-formu{
    position: relative;
}
.form-overlay2{
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    text-align: center;
    padding:0 10%;
    font-weight: 600;

    &.active{
        opacity: 1;
        visibility: visible;
    }
}

.yonetim-kurulu{
    >* {
        > * {
            > *:nth-child(1) {
                position: relative;
                overflow: hidden;
                display:flex;
                align-items:flex-end;
                justify-content: center;
                &:before,
                button{
                    content:'';
                    position: absolute;
                    transition:all 0.2s ease;
                    opacity:0;
                    visibility: hidden;
                    
                }
                &:before{
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background-color:rgba(0,0,0,0.6);
                    z-index: 1;
                }
                button{
                    display:inline-flex;
                    justify-content:center;
                    align-items:center;
                    
                    content:'Biyografisini Oku';
                    padding:0 1rem;
                    
                    height:50px;
                    font-size:13px;
                    bottom: 20px;

                    transform:translate(0,50px);
                    z-index:2;
                    color:white;
                    background-color:$brand-blue-light;
                }

                
            }
        }
        &:hover{
            > * {
                > *:nth-child(1) {
                    button,
                    &:before{
                        opacity:1;
                        visibility: visible;
                    }
                    button{
                        transform:translate(0,0);
                    }
    
                    
                }
            }
        }
    }
    img{
        min-width: 100%;
    }

}
.top-header-skeleton{
    display:none;
    &.active{
        display:block;
    }
}

.top-header{
    position: relative;
    z-index:20;

    .logo-blok {
        img{
            height:100px;
        }
    }

    &.active{
        position:fixed;
        width:100%;
        top:0;
        left:0;
        z-index:20;

        >*:nth-child(1) {
            display:none;

        }

        .logo-blok {
            img{
                height:60px;
            }
        }
        .header-blok-2{
            .row > *{
                height: 80px;
                display:none;
                margin-top:0px!important;
                margin-bottom:0px!important;
            }
        }
        a.hesap-no-link{
            display:none!important;
        }

        img{
        }

        &:hover{

            img{
            }
        }
    }
    
}

.form-overlay {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left:0;
    text-align: center;
    padding:0 10%;
    font-weight: 600;
    
    display:flex;
    align-items:center;
    justify-content: center;

    &.active{
        opacity: 1;
        visibility: visible;
    }
}

.ca{
    .ca-header{
        cursor: pointer;
    }
    .ca-body{
        display: none;
    }
    &.active {
        >.ca-body{
            display: initial;
        }
    }
}
.accordion-bagis{
    margin-bottom: 0.5rem;
    >.ca-header{
        background: $brand-green;
        color:white;
        padding:15px 20px;
    }
    >.ca-body{

        .sub-price{
            text-align: right;
        }
        .campaign-price{
            white-space: nowrap;
        }
        table{
            margin-bottom: 0!important;
        }
    }
}

.accordion-bagis-info{
    padding-left:8px!important;
    padding-right:8px!important;
    
    >.ca-header{
        color:$brand-green;
    }
    >.ca-body{
        font-weight: normal;
        padding-top:1rem;
        
    }
}

.bagis-counter{
    input{
        width:100%;
        border:solid #dedede 1px;
        margin:0 5px;
        padding:0 5px;
        color:#666;
        text-align:center;

        &:focus,
        &:active{
            outline: 0;
            border-color:$brand-green!important;
        }
    }
    i{
        cursor: pointer;
        color:#555;
        &:first-child{
            margin-right:3px;
        }
        &:last-child{
            margin-left:3px;
        }
    }
}
.total-price{
    font-size:20px;
    padding-right: 10px;
}

@import "_responsive.scss";